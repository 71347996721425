import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import BaseLink from "./base-link"
import ArticleCard from "./article-card"

const BaseKeepReadingBlog = ({ slug }) => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 3, sort: { fields: [createdAt], order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
    }
  `)

  const articles = allStrapiArticle.nodes.filter((node) => node.slug !== slug)
  return (
    <div className="mx-auto max-w-screen-xl px-10">
      <h4 className="my-8 w-full text-5xl font-semibold xl:w-[80%]">
        Sigue leyendo
      </h4>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {articles.map((article) => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </div>
      <div className="mt-12 flex justify-center">
        <div className="flex w-full justify-center lg:w-1/3">
          <BaseLink to="/blog" className="w-full flex-shrink-0">
            Todos los artículos
          </BaseLink>
        </div>
      </div>
    </div>
  )
}

BaseKeepReadingBlog.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default BaseKeepReadingBlog
