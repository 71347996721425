import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import BlocksRenderer from "../components/blocks-renderer"
import Seo from "../components/seo"
import BaseHeader from "../components/base-header"
import BaseSection from "../components/base-section"
import BaseSeparator from "../components/base-separator"
import BaseSocialShare from "../components/social/base-social-share"
import BaseKeepReadingBlog from "../components/base-keep-reading-blog"
import BaseBreadCrumbs from "../components/blog/base-breadcrumbs"
import BaseSectionTitle from "../components/base-section-title"

const ArticlePage = ({ data, location }) => {
  const article = data.strapiArticle
  const defaultSeo = article.defaultSeo
  const seo = {
    metaTitle: defaultSeo?.metaTitle || article.title,
    metaDescription: defaultSeo?.metaDescription || article.description,
    shareImage: defaultSeo?.metaDescription || article.cover,
    url: `blog/${article?.slug}`,
  }

  return (
    <Layout as="article">
      <Seo seo={seo} />
      <BaseBreadCrumbs location={location} title={article.title} />

      <BaseHeader className="flex flex-wrap justify-between">
        <BaseSectionTitle>{article.title}</BaseSectionTitle>
        <div className="flex flex-col items-end justify-end">
          <span className="flex-shrink-0 text-brand-primary">
            {article.createdAt}
          </span>
        </div>
      </BaseHeader>

      <BaseSection>
        <div className="flex justify-center">
          <GatsbyImage
            className="w-full"
            image={getImage(article?.cover?.localFile)}
            alt={article?.cover?.alternativeText || ""}
          />
        </div>
        <div className="px-4 lg:px-20">
          <BaseSectionTitle level={2} className="mt-8 xl:w-[80%]">
            {article.subtitle}
          </BaseSectionTitle>
          <BaseSectionTitle
            level={3}
            className="mt-8 w-full text-brand-primary xl:w-[80%]"
          >
            {article.description}
          </BaseSectionTitle>
          <BlocksRenderer blocks={article.blocks || []} />

          <BaseSocialShare quote={article.title} url={location.href} />
        </div>
      </BaseSection>

      <BaseSeparator />

      <BaseKeepReadingBlog slug={article.slug} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      subtitle
      description
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          id
          url
          localFile {
            url
          }
        }
      }
      blocks {
        ...Blocks
      }
      createdAt(formatString: "DD [de] MMMM[, ]YYYY", locale: "ES")
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 2.1
              quality: 90
              transformOptions: { cropFocus: CENTER }
              height: 720
            )
          }
        }
      }
    }
  }
`

export default ArticlePage
